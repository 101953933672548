@import "../../../style/helpers/index";

.quiz-toolbar {
  position: fixed;
  bottom:0;
  right:0;
  left:0;
  background:#536476;
  @include clearfix;
  font-size:20px;
  color:white;
  height:63px;
  z-index: 2;
  .back, .next {
    width:200px;
    height:100%;
    cursor: pointer;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .back {
    float:left;
    padding:16px 40px 16px 0px;
    text-align: right;
    span {
      &:before {
        display: inline-block;
        margin-right:14px;
        width:12px;
        height:22px;
        vertical-align: middle;
        background:url('../images/white-arrow.svg') no-repeat center;
        background-size:12px 22px;
        content:'';
      }
    }
  }
  .next {
    position: relative;
    float:right;
    text-align: left;
    padding:16px 0px 16px 30px;
    span {
      @include absolute-full;
      line-height:66px;
      padding-left:30px;
    }
    // span {
    //   &:after {
    //     position: absolute;
    //     top:0;
    //     bottom:0;
    //     margin:auto;
    //     content:'';
    //   }
    // }
    &:before {
      position: absolute;
      top:0;
      bottom:0;
      margin:auto;
      content:'';
    }
    &.is-mark {
      background:#55a3ff;
      &:before {
        right:30px;
        width:32px;
        height:32px;
        background:url('../images/right.svg') no-repeat center;
        background-size:32px 32px;
      }
    }
    &.is-right, &.is-false {
      &:before {
        right:40px;
        width:12px;
        height:22px;
        background:url('../images/next.svg') no-repeat center;
        background-size:12px 22px;
      }
      &.is-end {
        &:before {
          content:none;
          // right:30px;
          // width:32px;
          // height:32px;
          // background:url('../images/end.svg') no-repeat center;
          // background-size:32px 32px;
        }
        span {
          padding:0;
          text-align: center;
        }
      }
    }
    &.is-right {
      background:#1cc4cc;
    }
    &.is-false {
      background:#ef6b69;
    }
  }
  .quiz-number {
    position: absolute;
    right:0;
    left:0;
    bottom:0;
    z-index: -1;
    margin:auto;
    width:100%;
    text-align: center;
    height:100%;

    ul {
      display: inline-block;
      @include vertical-center;
      height:100%;
      @include custom-media(769, 880) {
        max-width: calc(100% - 360px);
        overflow: auto;
        white-space: pre;
      }
    }
    li {
      display: inline-block;
      vertical-align: middle;
      margin-left:21px;
      width:32px;
      height:32px;
      border-radius: 50%;
      cursor: pointer;
      &:first-child {
        margin-left:0;
      }
      &.is-this {
        background:rgba(#fff, 0.3);
        &.is-true {
          width:32px;
          height:32px;
          font-size:24px;
          line-height:32px;
          background:#1cc4cc;
        }
        &.is-false {
          width:32px;
          height:32px;
          font-size:24px;
          line-height:32px;
          background:#ef6b69;
        }
      }
      &.is-true, &.is-false {
        font-size:0;
      }
      &.is-true {
        width:22px;
        height:22px;
        background:url('../QuizPublishing/img/quiz-true.svg') no-repeat center;
        background-size:22px 22px;
      }
      &.is-false {
        width:22px;
        height:22px;
        background:url('../QuizPublishing/img/quiz-false.svg') no-repeat center;
        background-size:22px 22px;
      }
    }
  }
}