@import '../../style/helpers/index';

.myprofile-layout {
  margin: 0px auto 0px auto;
  width: 1200px;
  padding-top:128px;
  padding-bottom:100px;

  .imgarea, .textarea {
    display: inline-block;
    vertical-align: top;
  }

  .imgarea {
    margin-right: 80px;
    text-align: center;

    .img {
      position: relative;
      width: 227px;
      height: 227px;
      border:2px dashed #abb9c5;
      border-radius: 4px;
      background: #f7f9fb url('./smile.svg') no-repeat center 40%;
      background-size: auto;
      overflow: hidden;
      &.en {
        &::after {
          content: "Your Photo Here",
        }
      }
      &:after {
        position: absolute;
        color:#abb9c5;
        left:0;
        width:100%;
        bottom:52px;
        font-size:16px;
        font-weight:500;
        content:'이미지를 추가하세요';
      }
    }
    img {
      @include absolute-full(img);
      z-index: 1;
    }

    input {
      display: none;
    }
    label {
      position: absolute;
      width:34px;
      height:37px;
      right:10px;
      top:10px;
      cursor: pointer;
      background: url('./add-img4.png') no-repeat center;
      background-size: contain;
      z-index: 2;
    }

    p {
      margin-top: 20px;
      font-size: 24px;
      letter-spacing: -0.015em;
      color: #333a53;
      font-weight: 500;
    }
  }

  .textarea {
    position: relative;
    width: calc(100% - 307px);

    .classmode-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 34px;
      padding: 0 26px;
      font-size: 16px;
      line-height: 34px;
    }

    table {
      width: 100%;
      font-size: 20px;

      tr {
        line-height: 53px;

        &.l-inputwrap {

          // display: none;
          input::placeholder {
            font-weight: 300;
          }
        }

        &.pw-chk {
          .inputwrap {
            margin-top: 13px;
          }
        }

        &.pw-chk-repeat {
          .inputwrap {
            padding-top: 20px;
            padding-bottom: 17px;
          }
        }
      }

      th {
        color: #7f92a3;
        width: 154px;
        font-weight: 500;
      }

      td {
        color: #333a53;

        input {
          padding-bottom: 10px;
        }
      }
    }
  }
}

.myprofile-layout-wrap {
  background: white;
  border-radius: 16px;
  min-height: 849px;
  padding:40px;
}